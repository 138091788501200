<template>
  <div class="moreListShort cur_p">
    <el-popover
      width="250"
      placement="bottom"
      trigger="hover"
      transition="el-zoom-in-top"
      v-if="list && list.length"
    >
      <template #reference>
        <div class="desc inline">
          <div class="text">{{list[0]?.modelId ? list[0]?.modelName : list[0]}}</div>
          <el-icon class="icon"><ArrowDown /></el-icon>
        </div>
      </template>
      <div class="encodedList">
        <div class="single" v-for="item in list" :key="item">
                {{item?.modelId ? item.modelName : item }}
          </div>
      </div>
    </el-popover>
  </div>
</template>
<script setup>
import {ArrowDown} from "@element-plus/icons-vue";
import {defineProps, watch, toRefs ,ref} from "vue";
const localList = ref([]);
const props = defineProps({
  list: {
    type: [Array, String],
    default: () => [],
  },
});
const { list } = toRefs(props);
function setList (){
  if (typeof list.value === "string") {
    if (list.value.indexOf("/") !== -1) {
      localList.value = list.value.split("/");
    } else if (list.value.indexOf(",") !== -1) {
      localList.value = list.value.split(",");
      }else{
        localList.value = [list.value];
      }
  }else if(Array.isArray(list.value)){
    localList.value = list.value.filter(item => !item.discard);
  }
}
watch(list, (newVal) => {
  setList()
},{
  immediate: true,
})
</script>

<script>
import { ArrowDown } from "@element-plus/icons-vue";
import { reactive, toRefs } from "vue";
export default {
  name: "moreListShort",
};
</script>

<style lang="scss" scoped>
.moreListShort{
  font-size: 14px;
  .desc {
  color: #737480;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
  &.one {
    @include textOverflow(50px);
    display: inline-block;
    line-height: 1.3;
  }
  .text {
    display: inline-block;
    @include textOverflow(50px);
    line-height: 1.3;
  }
  .icon {
    position: relative;
    right: -4px;
  }
}
}

</style>
